/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from 'react';
import "./login.scss";
import { BallBeat } from '../../Loader/loader';
import Snackbar from '@mui/material/Snackbar';
import { userLogin, forgotPassword, resetPasswords } from '../../Redux/ActionCreators/authAction';
// import { userLogin, acceptInvite, forgotPassword, resetPasswords } from '../../../Redux/ActionCreators/Common/authAction';
import { clearErrors } from '../../Redux/ActionCreators/errorAction';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';


function Login() {
    const [defaultShowSignUp, setDefaultShowSignUp] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [otp, setOtp] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isResetPassword, setISResetPassword] = useState(false);
    const [resetPasswordComplete, setResetPasswordComplete] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [otpEmail, setOtpEmail] = useState('');
    const [isOtpEmailLoading, setIsOtpEmailLoading] = useState(false);
    const [otpEnable, setOtpEnable] = useState(false);
    const [emailOtpScreen, setEmailOtpScreen] = useState(true);
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [successEnable, setSuccessEnable] = useState(false);
    const { t, i18n } = useTranslation();
    const [successMessageText, setSuccessMessageText] = useState('');
    const [responseStatus, setResponseStatus] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const error = useSelector(state => state.error)
    useEffect(() => {
        // if (window.location.pathname.split('/')[2]) {
        //     const id = btoa(window.location.pathname.split('/')[2])
        //     dispatch(verifyEmail(id));
        // }

        if (window.location.href.includes('otp')) {
            setDefaultShowSignUp(false);
            setOtp(window.location.search.split("=")[1]);
        }


        if (localStorage.getItem('token')) {
            const details = JSON.parse(localStorage.getItem("profileDetails"));
            if (details?.roleId === "1") {
                (window.location.pathname === '/' || window.location.pathname === '/login') && (
                    navigate('/users')
                )
            }
            else {
                (window.location.pathname === '/' || window.location.pathname === '/login') && (
                    navigate('/home')
                )
            }

        } else {
            window.location.pathname === '/' && (
                navigate('/login')
            )
        }

    }, [])


    const onEmailChange = (e) => {
        setEmailError('');
        setEmail(e.target.value);
    }

    const onChangePassword = (e) => {
        setPasswordError('');
        setPassword(e.target.value);
    }


    const loginUser = async () => {
        setIsLoading(true);
        setResponseStatus('')
        let emailvalue = email;
        // eslint-disable-next-line no-useless-escape
        let emailrex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!emailvalue) {
            setEmailError(t('Email cant be blank'));
            setIsLoading(false);
            return false;
        }
        else if (!emailrex.test(String(emailvalue))) {
            setEmailError(t("Email is not valid"));
            setIsLoading(false);
            return false;
        }
        else if (password === "") {
            setPasswordError(t('Please enter password'));
            setIsLoading(false);
        }
        else {
            let login = {
                emailId: email,
                password: btoa(password.toString())
            }

            const res = await dispatch(userLogin(login));
            if (res?.type === 'LOGIN_USER') {
                i18n.changeLanguage(res.payload.languageCode);
            }
            setTimeout(() => {
                if (error.successMessage === false) {
                    setIsLoading(false);
                }
            }, 1000);

        }
    }

    const loginOnKeyPress = async (e) => {
        if (e.which === 13 || e.keyCode === 13) {
            setIsLoading(true);
            setResponseStatus('')
            let emailvalue = email;
            // eslint-disable-next-line no-useless-escape
            let emailrex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!emailvalue) {
                setEmailError(t('Email cant be blank'));
                setIsLoading(false);
                return false;
            }
            else if (!emailrex.test(String(emailvalue))) {
                setEmailError(t("Email is not valid"));
                setIsLoading(false);
                return false;
            }
            else if (password === "") {
                setPasswordError(t('Please enter password'));
                setIsLoading(false);
            }
            else {
                let login = {
                    emailId: email,
                    password: btoa(password.toString())
                }

                const res = await dispatch(userLogin(login));
                if (res?.type === 'LOGIN_USER') {
                    i18n.changeLanguage(res.payload.languageCode);
                }
                localStorage.getItem('token') !== null ?
                    navigate('/home')
                    : navigate('/login')
                setTimeout(() => {
                    if (error.successMessage === false) {
                        setIsLoading(false);
                    }
                }, 1000);

            }
        }
    }

    const resetPassword = () => {
        setISResetPassword(true);
        setEmailError('');
    }

    const handleChange = otp => setOtp(otp);

    const onOtpEmailChange = (e) => {
        setEmailError('');
        setOtpEmail(e.target.value);
    }

    const handleOtpEmail = async () => {
        // eslint-disable-next-line no-useless-escape
        let emailrex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!otpEmail) {
            setEmailError(t(`Email can't be blank`));
            return false;
        }
        else if (!emailrex.test(String(otpEmail))) {
            setEmailError(t("Email is not valid"));
            return false;
        }
        else {
            setIsOtpEmailLoading(true);
            await dispatch(forgotPassword(otpEmail));
            localStorage.setItem('email', otpEmail);
            await setIsOtpEmailLoading(false);
            await setOtpEnable(true);
            await setEmailOtpScreen(false);

        }
    }


    const onOtpEmailChangeKeyPress = async (e) => {
        // eslint-disable-next-line no-restricted-globals
        if (e.which === 13 || event.keyCode === 13) {
            // eslint-disable-next-line no-useless-escape
            let emailrex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!otpEmail) {
                setEmailError(t('Email cant be blank'));
                return false;
            }
            else if (!emailrex.test(String(otpEmail))) {
                setEmailError(t("Email is not valid"));
                return false;
            }
            else {
                setIsOtpEmailLoading(true);
                await dispatch(forgotPassword(otpEmail));
                localStorage.setItem('email', otpEmail);
                await setIsOtpEmailLoading(false);
                await setOtpEnable(true);
                await setEmailOtpScreen(false);

            }
        }
    }

    const handleOtpCheck1 = async () => {
        if (newPassword === "") {
            setSuccessEnable(true);
            setSuccessMessageText(t("Please enter new password"));
        }
        else if (confirmNewPassword === "") {
            setSuccessEnable(true);
            setSuccessMessageText(t("Please enter confirm password"));
        }
        else if (newPassword !== confirmNewPassword) {
            setSuccessEnable(true);
            setSuccessMessageText(t("Password did not match"));
        }
        else if (otp.length < 6) {
            setSuccessEnable(true);
            setSuccessMessageText(t("Please enter all the code fields"));
        }
        else {
            setIsOtpEmailLoading(true);

            let obj = {
                "emailId": localStorage.getItem('email'),
                "passWord": btoa(newPassword.toString()),
                "otp": otp
            }

            const res = await dispatch(resetPasswords(obj));
            if (res?.type === 'RESET_PASSWORD') {
                await setResetPasswordComplete(true);
                await setIsOtpEmailLoading(false);
            }
        }
    }

    const onPasswordChange = async (e) => {
        // eslint-disable-next-line no-restricted-globals
        if (e.which === 13 || event.keyCode === 13) {
            if (newPassword === "") {
                setSuccessEnable(true);
                setSuccessMessageText(t("Please enter new password"));
            }
            else if (confirmNewPassword === "") {
                setSuccessEnable(true);
                setSuccessMessageText(t("Please enter confirm password"));
            }
            else if (newPassword !== confirmNewPassword) {
                setSuccessEnable(true);
                setSuccessMessageText(t("Password did not match"));
            }
            else {
                setIsOtpEmailLoading(true);
                let obj = {
                    otp: otp,
                    password: btoa(newPassword.toString())
                }
                // await dispatch(acceptInvite(obj));
                // await props.isAuthorized && localStorage.getItem('token') !== null ?
                //     navigate('/user-dashboard')
                //     : navigate('/login')
                // await setIsOtpEmailLoading(false);
            }
        }
    }

    const handleOtpCheck = async () => {
        if (newPassword === "") {
            setSuccessEnable(true);
            setSuccessMessageText(t("Please enter new password"));
        }
        else if (confirmNewPassword === "") {
            setSuccessEnable(true);
            setSuccessMessageText(t("Please enter confirm password"));
        }
        else if (newPassword !== confirmNewPassword) {
            setSuccessEnable(true);
            setSuccessMessageText(t("Password did not match"));
        }

        else {
            setIsOtpEmailLoading(true);
            let obj = {
                otp: otp,
                password: newPassword
            }
            // await dispatch(acceptInvite(obj));
            // props.isAuthorized && localStorage.getItem('token') !== null ?
            //     navigate('/user-dashboard')
            //     : navigate('/login')

            await setIsOtpEmailLoading(false);
        }
    }


    const redirectTologin = () => {
        window.location.reload()
    }




    const snackClosed = () => {
        dispatch(clearErrors());
        setSuccessEnable(false);
        setSuccessMessageText('')
    }

    const domainDetail = JSON.parse(localStorage.getItem('domainDetails'));

    return (
        <Fragment>
            <div className="row bg-img" style={{ background: `url('/images/bg-img1.png') no-repeat center center fixed`, height: '100vh', width: '100%', backgroundColor: '#fff', marginLeft: window.innerWidth > 767 ? 0 : 0 }} >
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
                    open={error.successMessage}
                    onClose={() => snackClosed()}
                    autoHideDuration={4000}
                >
                    <Alert onClose={snackClosed} severity="error" sx={{ width: '100%' }}>
                        {error.successMessageText}
                    </Alert>
                </Snackbar>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
                    open={error.successEnable}
                    onClose={() => snackClosed()}
                    autoHideDuration={4000}
                >
                    <Alert onClose={snackClosed} severity="success" sx={{ width: '100%' }}>
                        {error.successMessageText}
                    </Alert>
                </Snackbar>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
                    open={successEnable}
                    onClose={() => snackClosed()}
                    autoHideDuration={4000}
                >
                    <Alert onClose={snackClosed} severity="error" sx={{ width: '100%' }}>
                        {successMessageText}
                    </Alert>
                </Snackbar>

                {defaultShowSignUp ?
                    <div className='login-holder'>

                        {
                            isResetPassword === false ?
                                <Fragment>
                                    <div title={domainDetail?.isWhiteLabel === 1 ? domainDetail?.displayName : 'Gappeo'} style={{ backgroundImage: `url(${domainDetail?.isWhiteLabel === 1 ? domainDetail?.logoImage : '/images/logo.png'})`, backgroundPosition: 'center center', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', width: '100%', height: 65 }}>

                                    </div>
                                    {/* <img src={domainDetail?.isWhiteLabel === 1 ? 'https://devapi.gappeo.com/images/Tenent/163/Logo/638627770741472860_42760669-4726-4817-b5db-94df7bd91001.png' : '/images/logo.png'} alt='' height='70px' /> */}
                                    <h2 className='forgot-btn'>{t(`Welcome to ${domainDetail?.tenantName}`, { ns: 'profile' })}</h2>
                                    <div style={{ marginTop: 30 }}>
                                        {
                                            <span style={{ fontWeight: 600, color: 'red', fontSize: 14, paddingTop: '5px', position: 'relative', marginBottom: -10 }}>{responseStatus}</span>
                                        }
                                        <div className="form-group mt-2">
                                            <div className="has-search"  >
                                                <span className="form-control-feedback" style={{ marginTop: 5 }}>
                                                    <img src="/images/email.png" alt='' />
                                                </span>
                                                <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder={t('Email', { ns: 'profile' })} value={email} onChange={(e) => onEmailChange(e)} onKeyPress={(e) => loginOnKeyPress(e)} />
                                            </div>
                                            <div className='error'>{emailError}</div>
                                        </div>
                                        <div className="form-group">
                                            <div className="has-search"  >
                                                <span className="form-control-feedback" >
                                                    <img src="/images/lock.png" alt='' />
                                                </span>
                                                <input type="password" className="form-control" aria-describedby="emailHelp" value={password} placeholder={t('Password', { ns: 'profile' })} onChange={(e) => onChangePassword(e)} onKeyPress={(e) => loginOnKeyPress(e)} />
                                            </div>
                                            <div className='error'>{passwordError}</div>

                                        </div>
                                        {/* <a onClick={() => resetPassword()}>Forgot Password?</a> */}
                                        <div className='position-relative'>
                                            <button onClick={() => loginUser()} disabled={isLoading} className="login-button position-relative">
                                                {isLoading ? <BallBeat
                                                    color={'#fff'}
                                                    loading={true}
                                                /> : (t("LOGIN"))}
                                            </button>

                                        </div>
                                        <h2 className='forgot-btn my-3' onClick={resetPassword}>{t('Forgot Password', { ns: 'profile' })} ?</h2>
                                        {
                                            domainDetail?.isWhiteLabel === 1 ? null :
                                                <Fragment>
                                                    <p style={{ color: '#3D4143', fontSize: 14 }}>{t('Need new account', { ns: 'profile' })}?
                                                    </p>
                                                    <h2 className='forgot-btn mt-0' onClick={() => navigate('/signup')}>{t('Sign up free', { ns: 'profile' })}</h2>
                                                </Fragment>
                                        }
                                    </div>
                                </Fragment>
                                :
                                <div className="login-holder otp-holder" style={{ width: '100%' }}>

                                    <h3>{t('Reset your password', { ns: 'profile' })}.</h3>
                                    {
                                        otpEnable === false && emailOtpScreen ? <Fragment>
                                            <p className='mt-2'>{t('Please enter your registered Email address', { ns: 'profile' })}.</p>
                                            <div className="form-group mt-3 w-100">
                                                <span>{responseStatus}</span>

                                                <input type="email" className="form-control" aria-describedby="emailHelp" placeholder={t('Email', { ns: 'profile' })} onChange={(e) => onOtpEmailChange(e)} onKeyPress={(e) => onOtpEmailChangeKeyPress(e)} value={otpEmail} />
                                            </div>
                                            <div className='error'>{emailError}</div>
                                            <button onClick={() => handleOtpEmail()} className="primary-button position-relative">
                                                {isOtpEmailLoading ? <BallBeat
                                                    color={'#fff'}
                                                    loading={true}
                                                /> : (t("CONTINUE"))}</button>

                                        </Fragment> :

                                            resetPasswordComplete === false ?
                                                <div className="otp-fields d-flex flex-column align-items-center mt-3">
                                                    <p>{t('Please enter the code that is sent to your mail.', { ns: 'profile' })}</p>
                                                    {/* <OtpInput
                                                        numInputs={6}
                                                        onChange={handleChange}
                                                        inputStyle="otp-input"
                                                        value={otp}
                                                        separator={<span>-</span>}
                                                    /> */}

                                                    <div className="form-group">
                                                        <input type="text" placeholder={t('Enter Otp', { ns: 'profile' })} className="form-control" autoComplete='off' onChange={(e) => handleChange(e.target.value)} />
                                                        <input type="password" placeholder={t('Enter new password', { ns: 'profile' })} className="form-control" autoComplete='off' onChange={(e) => setNewPassword(e.target.value)} />
                                                        <input type="password" placeholder={t('Confirm new password', { ns: 'profile' })} className="form-control" onChange={(e) => setConfirmNewPassword(e.target.value)} />
                                                    </div>

                                                    <button disabled={isOtpEmailLoading === false ? false : true} onClick={() => handleOtpCheck1()} className="primary-button position-relative">
                                                        {isOtpEmailLoading ? <BallBeat
                                                            color={'#fff'}
                                                            loading={true}
                                                        /> : "Reset"}</button>
                                                </div> : <div className="success-pasword-reset mt-2">
                                                    <p>{t('Password has been successfully changed', { ns: 'profile' })}</p>
                                                    <button onClick={() => redirectTologin()} className="primary-button mt-2">{t('Login', { ns: 'profile' })}</button>
                                                </div>
                                    }

                                </div>
                        }

                    </div> :
                    <div className="login-holder otp-holder">
                        <h3>{t('Set your password', { ns: 'profile' })}</h3>
                        {
                            <div className="otp-fields" style={{ width: window.innerWidth > 767 ? '60%' : '90%' }}>
                                <p className='d-none'>{t('Please enter the code that is sent to your mail.', { ns: 'profile' })}</p>
                                {/* <div style={{ display: 'none' }}>
                                        <OtpInput
                                            numInputs={6}
                                            onChange={handleChange}
                                            inputStyle="otp-input"
                                            value={otp}
                                            separator={<span>-</span>}

                                        />
                                    </div> */}
                                <input className='form-control d-none' onChange={(e) => handleChange(e.target.value)} style={{ height: 48 }} value={otp} />
                                <div className="form-group mt-3">
                                    <input type="password" placeholder={t('Enter new password', { ns: 'Profile' })} className="form-control" aria-describedby="emailHelp" onChange={(e) => setNewPassword(e.target.value)} onKeyPress={(e) => onPasswordChange(e)} />
                                    <input type="password" placeholder={t('Confirm new password', { ns: 'profile' })} className="form-control" aria-describedby="emailHelp" onChange={(e) => setConfirmNewPassword(e.target.value)} onKeyPress={(e) => onPasswordChange(e)} />
                                </div>

                                <button disabled={isOtpEmailLoading !== false} onClick={() => handleOtpCheck()} className="primary-button position-relative">
                                    {isOtpEmailLoading ? <BallBeat
                                        color={'#fff'}
                                        loading={true}
                                    /> : "Set"}
                                </button>
                            </div>
                        }

                    </div>
                }
            </div>

        </Fragment>
    )
}


export default Login