import React, { Fragment, useEffect, useState, useRef } from 'react';
import './candidateTest.scss';
import Webcam from "react-webcam";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { validateToken, validateCode, uploadImage, intiateTest, getTestSummary, getQuestion, submitAnswer, feedbackSubmited, updateTestStatus, getStarterCode } from '../../Redux/ActionCreators/candidateTestAction';
import { useSelector } from 'react-redux';
import { dataURLtoFile, removeScript, StarIcon } from '../../utils/miscelleneous';
import { Snackbar, Alert } from '@mui/material';
import ProgressBar from '../../utils/progressBar';
import Main from '../../utils/CodeEditor/main';
import { Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import $ from 'jquery';
const emojis = [{ id: 1, emoji: '/images/strong-dislike.png', text: 'Strongly Dislike', color: '#FC4A4A' },
{ id: 2, emoji: '/images/dislike.png', text: 'Dislike', color: '#D86B52' },
{ id: 3, emoji: '/images/not-sure.png', text: 'Not Sure', color: '#EDB325' },
{ id: 4, emoji: '/images/likes.png', text: 'Like', color: '#B9BB5E' },
{ id: 5, emoji: '/images/strong-like.png', text: 'Strongly Like', color: '#5EBB97' }
]

let timerInterval = null;

export default function CandidateTest() {

    const [isCookie, setIsCookie] = useState(false);
    const [active, setActive] = useState('summary');
    const [isBrowser, setIsBrowser] = useState(true);
    const [isOs, setIsOs] = useState(true);
    const [isNetwork, setIsNetwork] = useState(true);
    const [isScreen, setIsScreen] = useState(true);
    const [checked, setChecked] = useState(false);
    const [imageSrc, setImageSrc] = useState('');
    const [identity, setIdentity] = useState('');
    const [loading, setLoading] = useState(true);
    const [code, setCode] = useState('');
    const [codeError, setCodeError] = useState('');
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState('');
    const [btnloading, setBtnLoading] = useState(false);
    const [option, setOption] = useState('');
    const [qid, setQid] = useState('');
    const [nextLoading, setNextLoading] = useState(false);
    const [captureCount, setCaptureCount] = useState(0);
    const [rating, setRating] = useState(0);
    const [comment, setComment] = useState('');
    const [ratingError, setRatingError] = useState('');
    const [successMessage, setSuccessMessage] = useState(false);
    const [successMessageText, setSuccessMessageText] = useState('');
    const [show, setShow] = useState(false);
    const [isAllow, setIsAllow] = useState(false);
    const [error, setError] = useState('');
    const { t } = useTranslation();
    const [message, setMessage] = useState('');
    const [timer, setTimer] = useState(10);
    const [isRunning, setIsRunning] = useState(false);
    const [loadingFeedback, setLoadingFeedback] = useState(false);
    const [activeEmoji, setActiveEmoji] = useState('');
    const [isAlert, setIsAlert] = useState(false);
    const [answerText, setAnswerText] = useState('');
    const [isConfirm, setIsConfirm] = useState(false);
    const dispatch = useDispatch();

    const { candidateDetails, testDetails, token, testSummary, question, isTestCompleted, isNextTest } = useSelector(state => state.candidateTest);



    const goFullScreen = () => {
        const element = document.body;
        if (element.requestFullScreen) {
            element.requestFullScreen();
        } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) {
            element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) {
            element.msRequestFullscreen();
        }
    };

    useEffect(() => {
        const firstRender = async () => {

            if (window.location.pathname.split('/')[2]) {
                if (active === 'summary') {
                    const res = await dispatch(validateToken(window.location.pathname.split('/')[2]));
                    console.log(res?.payload)
                    if (res?.type === 'VALIDATE_TOKEN') {
                        setIsCookie(true);
                        setCode(res?.payload?.invitation?.verificationCode);
                        setName(res.payload.invitation?.name);
                        if (res.payload?.test?.testStatus === 3) {
                            setActive('test-complete')
                        }
                    }
                    else if (res?.type === 'LINK_EXPIRE') {
                        setShow(true);
                        setMessage(t('Link Expired.Please contact your admin.'))
                    }
                }
                setLoading(false);
            }

            if (active === 'diagnostic') {
                const { browser, operatingSystem, screenResolution, networkSpeed, } = checkEnvironment();
                if (browser === 'Unknown') {
                    setIsBrowser(false);
                }
                else if (operatingSystem === 'Unknown') {
                    setIsOs(false);
                }
                else if (networkSpeed === 'Unknown' || networkSpeed === '2g') {
                    setIsNetwork(false);
                }
            }
        }
        firstRender()
    }, [])

    useEffect(() => {
        // Check if the browser supports webcam
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
            setSuccessMessage(true);
            setSuccessMessageText('Webcam is not supported by this device or browser.');
            return;
        }

        // Request access to the webcam
        navigator.mediaDevices.getUserMedia({ video: true })
            .then(stream => {
                // Set the video source to the webcam stream
                if (webcamRef.current) {
                    // videoRef.current.srcObject = stream;
                }
            })
            .catch(err => {
                // Handle errors (e.g., permission denied, no webcam)
                console.log(err.message)
                if (err.message === 'Permission denied') {

                } else {
                    setIsAllow(true);
                    setError(err.message)
                }
            });
    }, [active === 'diagnostic'])

    useEffect(() => {
        document.addEventListener('contextmenu', function (e) {
            e.preventDefault();
        });

        // Prevent Ctrl+S, Ctrl+C & Ctrl+V
        document.onkeydown = function (e) {
            e = e || window.event; //Get event
            if (e.ctrlKey) {
                var c = e.code; //Get key code
                if (["KeyS", "KeyC", "KeyV"].includes(c)) {
                    e.preventDefault();
                    e.stopPropagation();
                    setSuccessMessage(true);
                    setSuccessMessageText(t('Ctrl + S, Ctrl+C, Ctrl+V not allowed'));
                    // setCount(count + 1);
                }
            }
            if (e.keyCode === 123) {
                return false;
            }

            // disable I key
            if (e.ctrlKey && e.shiftKey && e.keyCode === 73) {
                return false;
            }

            // disable J key
            if (e.ctrlKey && e.shiftKey && e.keyCode === 74) {
                return false;
            }

            // disable U key
            if (e.ctrlKey && e.keyCode === 85) {
                return false;
            }
        };
        // if (active === 'test') {
        // detect tab switching
        document.addEventListener("visibilitychange", (event) => {
            if (document.visibilityState !== "visible") {
                setSuccessMessage(true);
                setSuccessMessageText(t('Tab switching not allowed'));
                // setCount(count + 1);
            }
        });

        // full screen detection - chrome
        document.addEventListener("webkitfullscreenchange", function () {
            // full screen disabled
            if (!document.fullscreenElement) {
                setSuccessMessage(true);
                setSuccessMessageText(t('Exiting Full Screen not allowed'));
                // setCount(count + 1);
                goFullScreen();
            }
        });

        // }

        return (() => {
            document.removeEventListener("visibilitychange", (event) => {
                if (document.visibilityState !== "visible") {
                    //   setShow(true);
                    //   setViolations("Tab switching not allowed");
                    //   setCount(count + 1);
                }
            });
            document.removeEventListener("webkitfullscreenchange", function () {
                // full screen disabled
                if (!document.fullscreenElement) {
                    //   setShow(true)
                    //   setViolations("Exiting Full Screen not allowed");
                    //   setCount(count + 1);
                }
                goFullScreen();
            });
        })
    });

    useEffect(() => {
        if (active !== 'summary' && active !== 'diagnostic') {
            goFullScreen();
        }
    })

    const changeActive = (val) => {
        setActive(val)
    }

    const validate = async () => {
        if ((name.trim('') === '')) {
            setNameError(t(`Name can't empty`))
        }
        else if ((code === '')) {
            setCodeError(t(`code can't empty`))
        }
        else if ((code?.length > 6)) {
            setCodeError(t('Code must be 6 digits'))
        } else if ((code?.length < 6)) {
            setCodeError(t('Code must be 6 digits'))
        } else {
            await setBtnLoading(true);
            const res = await dispatch(validateCode(window.location.pathname.split('/')[2], code, name, token));
            if (res?.type === 'VALIDATE_CODE') {
                setActive('diagnostic');
            }
            setBtnLoading(false);
        }

    }



    const checkEnvironment = () => {
        // Check browser
        const userAgent = navigator.userAgent;
        let browser;

        if (userAgent.includes("Chrome")) {
            browser = "Google Chrome";
        } else if (userAgent.includes("Firefox")) {
            browser = "Mozilla Firefox";
        } else if (userAgent.includes("Safari")) {
            browser = "Apple Safari";
        } else if (userAgent.includes("Edge")) {
            browser = "Microsoft Edge";
        } else if (userAgent.includes("MSIE") || userAgent.includes("Trident/")) {
            browser = "Internet Explorer";
        } else {
            browser = "Unknown";
        }

        // Check operating system
        const platform = navigator.platform;
        let operatingSystem;

        if (platform.includes("Win")) {
            operatingSystem = "Windows";
        } else if (platform.includes("Mac")) {
            operatingSystem = "MacOS";
        } else if (platform.includes("Linux")) {
            operatingSystem = "Linux";
        } else {
            operatingSystem = "Unknown";
        }

        // Check screen resolution
        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;
        const screenResolution = `${screenWidth}x${screenHeight}`;

        // Check network speed (basic example)
        const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
        const networkSpeed = connection ? connection.effectiveType : "Unknown";

        // Log the results
        // console.log("Browser:", browser);
        // console.log("Operating System:", operatingSystem);
        // console.log("Screen Resolution:", screenResolution);
        // console.log("Network Speed:", networkSpeed);

        // You can return the results as an object if needed
        return {
            browser,
            operatingSystem,
            screenResolution,
            networkSpeed,
        };
    };

    // Call the function
    checkEnvironment();

    const webcamRef = React.useRef(null);
    const webcamRef1 = React.useRef(null);
    const videoConstraints = {
        width: 220,
        height: 155,
        facingMode: "user"
    };

    const reCapture = () => {
        setIdentity('');
        // resetTimer();
    }

    const capture = async () => {
        const imageSrc = webcamRef.current.getScreenshot();
        setImageSrc(imageSrc);
        var file = dataURLtoFile(imageSrc, 'user.png');
        const data = new FormData();
        data.append('token', candidateDetails?.invitationToken);
        data.append('file', file);
        await dispatch(uploadImage(data, 1, token));

    }



    const goToIdentity = async () => {
        await changeActive('identity');
    }



    const videoConstraints1 = {
        width: 416,
        height: 280,
        facingMode: "user"
    };

    const captureIdentity = async () => {
        const imageSrc = webcamRef1.current.getScreenshot();
        setIdentity(imageSrc);
        setIsConfirm(true);
        var file = dataURLtoFile(imageSrc, 'identity.png');
        const data = new FormData();
        data.append('token', candidateDetails?.invitationToken);
        data.append('file', file);
        await dispatch(uploadImage(data, 2, token));
        await setTimer(10);
        await setIsRunning(true);
    }

    const goAhead = async () => {
        await setNextLoading(true);
        await setIsConfirm(false);
        await getTestDetail();
        await setActive('test-summary');
        await setNextLoading(false);
    }

    const getTestDetail = async () => {
        const res1 = await dispatch(intiateTest(candidateDetails?.invitationToken, token));
        if (res1?.type === 'INTITATE_TEST') {
            const res = await dispatch(getTestSummary(candidateDetails?.candidateTestId, token))
            if (res?.type === 'TEST_DETAILS') {
                changeActive('test-summary');
                setIsRunning(true);
                setTimer(10);
            }
        }
        if (res1?.type === 'Test_Credit') {
            setShow(true);
            setMessage(res1?.payload)
        }

    }


    const onTimesUp = async () => {
        clearInterval(timerInterval);
        await setLoadingFeedback(true);
        timeLeft = 0
        if (timeLeft === 0) {
            const res1 = await dispatch(getTestSummary(candidateDetails?.candidateTestId, token));
            if (res1?.type === 'TEST_DETAILS') {
                const obj = {
                    "candidateTestDetailId": testSummary?.testDetails?.tests?.filter(el => el.candidateTestStatus === 2)[0]?.candidateTestDetailId,
                    "status": 3
                }
                await dispatch(updateTestStatus(obj, token));
                await dispatch(getTestSummary(candidateDetails?.candidateTestId, token));
                await setTimer(10);
                await setIsRunning(true);
                await changeActive('test-summary');
            }
        }
        await setLoadingFeedback(false);
    }



    const translatePage = (lang) => {
        const selectElement = document.querySelector('.goog-te-combo');
        if (selectElement) {
            selectElement.value = lang;
            selectElement.dispatchEvent(new Event('change'));
        }
        else {
            setTimeout(function () {
                $('.goog-te-combo').val(lang);
            }, 500);
        }
    }



    const startTest = async () => {
        await setLoadingFeedback(true);
        const res = await dispatch(getQuestion(candidateDetails?.invitationToken, token));
        if (res?.type === 'GET_QUESTION') {
            res.payload.question?.questionTypeID === 7 && await dispatch(getStarterCode(candidateDetails?.invitationToken, res.payload.question?.candidateTestId, res.payload.question.candidateQuestionId, res.payload.question?.languages[0]?.codingLanguage, token))
            if (res.payload.isTestCompleted) {
                await setActive('feedback');
            }
            else if (res.payload?.isNextTest) {
                console.log('dd')
                setActive('test-summary');
                translatePage('en')
            }
            else {

                await setActive('test');
                Minute = question?.questionTypeID === 7 ? await question?.duration : testSummary?.testDetails?.tests?.filter(el => el.candidateTestStatus === 2)[0]?.duration;
                // await setTime(duration);
                await startTimer();
                await translatePage(testSummary?.testDetails?.tests?.filter(el => el.candidateTestStatus === 2)[0]?.languageCode);
            }
        }
        await setLoadingFeedback(false);
    }


    const FULL_DASH_ARRAY = 283;
    const WARNING_THRESHOLD = 60;
    const ALERT_THRESHOLD = 10;

    const COLOR_CODES = {
        info: {
            color: "black"
        },
        warning: {
            color: "orange",
            threshold: WARNING_THRESHOLD
        },
        alert: {
            color: "red",
            threshold: ALERT_THRESHOLD
        }
    };


    var Minute = question?.questionTypeID === 7 ? question?.duration : testSummary?.testDetails?.tests?.filter(el => el.candidateTestStatus === 2)[0]?.duration;
    var Seconds = Math.round(60 * Minute);
    const TIME_LIMIT = Seconds;
    let timePassed = 0;
    let timeLeft = TIME_LIMIT;
    let remainingPathColor = COLOR_CODES.info.color;

    let isPaused = false;

    const startTimer = () => {
        timerInterval = setInterval(() => {
            if (!isPaused) {
                timePassed = timePassed += 1;
                timeLeft = TIME_LIMIT - timePassed;
                const ele = document.getElementById("base-timer-label");
                if (ele) {
                    ele.innerHTML = (timeLeft == 'NaN' ? 1 : formatTime(timeLeft));
                }
                setCircleDasharray();
                setRemainingPathColor(timeLeft);

                if (timeLeft === 0) {
                    onTimesUp();
                    document.getElementById("base-timer-label").innerHTML = 0
                }
                else if (timeLeft === 120) {
                    clearInterval(timerInterval); // pause the timer
                    isPaused = true
                    setIsAlert(true);
                    setTimeout(() => {
                        isPaused = false
                        setIsAlert(false); // resume the timer after the warning pause duration
                        startTimer();
                    }, 8000);
                }
            }
        }, 1000);

    }

    function formatTime(time) {
        const minutes = Math.floor(time / 60);
        let seconds = time % 60;

        if (seconds < 10) {
            seconds = `0${seconds}`;
        }

        return `${minutes}:${seconds}`;
    }

    function setRemainingPathColor(timeLeft) {
        const { alert, warning, info } = COLOR_CODES;
        if (timeLeft <= alert.threshold) {
            document
                .getElementById("base-timer-path-remaining")
                .classList.remove(warning.color);
            document
                .getElementById("base-timer-path-remaining")
                .classList.add(alert.color);
        } else if (timeLeft <= warning.threshold) {
            document
                .getElementById("base-timer-path-remaining")
                .classList.remove(info.color);
            document
                .getElementById("base-timer-path-remaining")
                .classList.add(warning.color);
        }
    }

    function calculateTimeFraction() {
        const rawTimeFraction = timeLeft / TIME_LIMIT;
        return rawTimeFraction - (1 / TIME_LIMIT) * (1 - rawTimeFraction);
    }

    function setCircleDasharray() {
        const circleDasharray = `${(calculateTimeFraction() * FULL_DASH_ARRAY).toFixed(0)} ${FULL_DASH_ARRAY}`;
        const ele = document.getElementById("base-timer-path-remaining");
        if (ele) {
            document.getElementById("base-timer-path-remaining").setAttribute("stroke-dasharray", circleDasharray);
        }
    }

    const selectOption = async (id, qid) => {
        setOption(id);
        setQid(qid);
    }

    const getNext = async () => {
        await setNextLoading(true);
        let obj = {
            "token": candidateDetails?.invitationToken,
            "candidateTestQuestionId": question?.questionTypeID === 5 ? question?.candidateQuestionId : qid,
            "selectedOptionIds": question?.questionTypeID === 5 ? [] : question?.questionTypeID === 8 ? [activeEmoji] : [option],
            "answerText": answerText
        }
        await dispatch(submitAnswer(obj, token));
        const res = await dispatch(getQuestion(candidateDetails?.invitationToken, token));
        console.log('339', res)
        if (res?.type === 'GET_QUESTION') {
            setOption('')
            setActiveEmoji('');
            setAnswerText('');
            if (res.payload.isTestCompleted) {
                await setActive('feedback');
                await setLoadingFeedback(true);
                const res2 = await dispatch(getTestSummary(candidateDetails?.candidateTestId, token))
                if (res2?.type === 'TEST_DETAILS') {
                    await onTimesUp();
                    await setActive('feedback');
                }
                setLoadingFeedback(false);
            }
            else if (res.payload?.isNextTest) {
                await setActive('test-summary');
                await setLoadingFeedback(true);
                const res1 = await dispatch(getTestSummary(candidateDetails?.candidateTestId, token))
                if (res1?.type === 'TEST_DETAILS') {
                    onTimesUp();
                    setTimer(10);
                    setIsRunning(true);

                }
                setLoadingFeedback(false);
            }
            else {
                await setActive('test');
            }

        }
        setNextLoading(false);
    }

    const getNextCodingQues = async () => {
        await setNextLoading(true);
        const res = await dispatch(getQuestion(candidateDetails?.invitationToken, token));
        console.log('339', res)
        if (res?.type === 'GET_QUESTION') {
            question?.totalQuestion !== question?.currentQuestion && await dispatch(getStarterCode(candidateDetails?.invitationToken, res.payload.question?.candidateTestId, res.payload.question?.candidateQuestionId, res.payload.question?.languages[0]?.codingLanguage, token))
            setOption('')
            if (res.payload.isTestCompleted) {
                await setActive('feedback');
                await setLoadingFeedback(true);
                const res2 = await dispatch(getTestSummary(candidateDetails?.candidateTestId, token))
                if (res2?.type === 'TEST_DETAILS') {
                    await onTimesUp();
                    await setActive('feedback');
                }
                setLoadingFeedback(false);
            }
            else if (res.payload.isNextTest) {
                await setActive('test-summary');
                await setLoadingFeedback(true);
                const res1 = await dispatch(getTestSummary(candidateDetails?.candidateTestId, token))
                if (res1?.type === 'TEST_DETAILS') {
                    onTimesUp();
                    setTimer(10);
                    setIsRunning(true);

                }
                setLoadingFeedback(false);
            }
            else {
                await setActive('test');
                question?.questionTypeID === 7 && await clearInterval(timerInterval);
                question?.questionTypeID === 7 && await startTimer();
            }
        }
        await setNextLoading(false);
    }



    const getSummary = () => {
        setActive('test-summary')
    }

    const closeWindow = () => {
        localStorage.clear();
        window.location.href = '/login'
        // window.location.reload();
    }

    useEffect(() => {
        let captureInterval;

        if (active === 'test') {
            captureInterval = setInterval(() => {
                if (captureCount < Math.round(testSummary?.questionCount / 2)) {
                    captureDuringTest();
                    setCaptureCount(prevCount => prevCount + 1);
                } else {
                    clearInterval(captureInterval); // Stop further captures after reaching the limit
                }
            }, (Math.round(testSummary?.duration / (testSummary?.questionCount / 2))) * 60 * 1000);
        }

        // Cleanup: Clear interval on component unmount or when not in 'test' mode
        return () => clearInterval(captureInterval);
    }, [captureCount, active]);



    const webcamRef3 = useRef(null);

    const captureDuringTest = async () => {
        const imageSrc = webcamRef3.current.getScreenshot();
        var file = dataURLtoFile(imageSrc, 'user.png');
        const data = new FormData();
        data.append('token', candidateDetails?.invitationToken);
        data.append('file', file);
        await dispatch(uploadImage(data, 3, token));
    };


    const handleStarClick = (selectedRating) => {
        setRating(selectedRating);
    };

    const submitFeedback = async () => {
        setLoadingFeedback(true);
        if (rating === 0) {
            setRatingError(t('Please Select rating'))
        } else {
            const obj = {
                "candidateTestId": candidateDetails?.candidateTestId,
                "feebBack": comment,
                "rating": rating
            }
            const res = await dispatch(feedbackSubmited(obj, token));
            if (res?.type === 'SUBMIT_FEEDBACK') {
                setActive('test-complete');
                removeScript();
            }
        }
        setLoadingFeedback(false);

    }


    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    useEffect(() => {
        let interval;
        const secRender = async () => {
            if (isRunning && timer > 0) {
                interval = setInterval(() => {
                    setTimer(timer - 1);
                }, 1000);
            } else if (timer === 0 || timer === 1) {
                if (active === 'identity') {
                    // await getTestDetail();
                    // await setActive('test-summary');
                }
                else if (active === 'test-summary') {
                    await setActive('test');
                    await startTest();
                }
                clearInterval(interval);
            }
        }

        secRender();


        return () => {
            clearInterval(interval);
        };
    }, [timer, isRunning]);

    const resetTimer = () => {
        setTimer(10);
        setIsRunning(false);
    };

    const onUserMediaError = (e) => {
        setIsAllow(true);
    }


    const snackClosed = () => {
        setSuccessMessage(false);
        setSuccessMessageText('')
    }

    console.log(testDetails)
    return (
        <Fragment>
            {/* {((active === 'test') || (!show && (active === 'summary')) || (active === 'diagnostic') || (active === 'identity')) ? */}
            <div className='d-flex justify-content-end'>
                <div id="google_translate_element" style={{ visibility: testSummary?.testDetails?.tests?.filter(el => el.candidateTestStatus === 2)[0]?.languageCode === 'en' ? 'hidden' : (show && (active === 'summary')) ? 'hidden' : 'visible' }}></div>
            </div>
            {/* null
            } */}
            <Dialog
                className='screen-loader'
                open={nextLoading}
                onClose={() => { }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {t('Loading')}...
            </Dialog>
            {loading ?
                <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}>
                    <img src='/images/spinner.svg' alt='' />
                </div>
                :
                (!show ?
                    <div className='candidate-test-holder'>
                        <Snackbar
                            anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
                            open={successMessage}
                            autoHideDuration={3000}
                            onClose={snackClosed}
                        >
                            <Alert onClose={snackClosed} severity="error" sx={{ width: '100%' }}>
                                {successMessageText}
                            </Alert>
                        </Snackbar>
                        {
                            (active === 'summary' ?
                                <Fragment>
                                    <div className='float-right'>

                                    </div>

                                    <div className='candidate-banner'>
                                        <img src='/images/banner.png' alt='' width={'100%'} />
                                    </div>
                                    <div className='test-summary'>
                                        {window.innerWidth > 767 && <h3>{t('Assessment Summary')}</h3>}
                                        <div className='d-flex mt-3'>
                                            {window.innerWidth > 767 &&
                                                <div className='test-list'>
                                                    {testDetails?.testDetails?.tests.sort((a, b) => a.orderId - b.orderId).map(el => {
                                                        return (
                                                            <div className='list-item'>
                                                                <div>
                                                                    <h4>{el.testName}</h4>
                                                                    <p>{el.questionCount} {t('Questions')} {el.duration} {t('Mins')} | {el.languageCode}</p>
                                                                </div>
                                                                {el.candidateTestStatus === 3 ?
                                                                    <img src='/images/tick-mark.svg' alt='' width={18} />
                                                                    :
                                                                    <img src='/images/grey-tick-mark.svg' alt='' />
                                                                }

                                                            </div>
                                                        )
                                                    })}
                                                    {
                                                        testDetails?.testDetails?.questions?.length > 0 &&
                                                        <div className='list-item'>
                                                            <div>
                                                                <h4>{t('Additional Questions')}</h4>
                                                                <p>{testDetails?.testDetails?.questions?.length} {t('Questions')}</p>
                                                            </div>
                                                        </div>
                                                    }
                                                    {testDetails?.testDescription !== null &&
                                                        <div className='mt-5'>

                                                            {window.innerWidth > 767 && <h3>Description </h3>}
                                                            <p>{testDetails?.testDescription}</p>
                                                        </div>}

                                                </div>
                                            }

                                            {window.innerWidth > 767 && <div style={{ borderLeft: '1px solid #D7DADB', margin: '0px 75px', minHeight: 450 }}></div>}
                                            <div className='test-list' style={{ width: window.innerWidth > 767 ? '32%' : '100%' }}>
                                                <h2>{t('Hi')} {candidateDetails?.name}</h2>
                                                <p>{t('Welcome to the assessment for')} {testDetails?.testName}.
                                                    {t('Please enter your test code to continue')}</p>
                                                {
                                                    (window.innerWidth < 767 && testDetails?.testDetails?.tests?.length) &&
                                                    <div className="accordion" id="accordionExample">
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="headingOne">
                                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                    {t('Assessment Summary')}
                                                                    <p>{testDetails?.testDetails?.tests?.length} {t('Tests')}, {testDetails?.questionCount} {t('Question')}, {testDetails?.duration} {t('minutes')}</p>
                                                                </button>
                                                            </h2>
                                                            <div id="collapseOne" className={`accordion-collapse collapse show`} aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                                <div className="accordion-body">
                                                                    <div className='test-list'>
                                                                        {testDetails?.testDetails?.tests.map(el => {
                                                                            return (
                                                                                <div className='list-item'>
                                                                                    <div>
                                                                                        <h4>{el.testName}</h4>
                                                                                        <p>{el.questionCount} {t('Questions')} {el.duration} {t('Mins')} | {el.languageCode}</p>
                                                                                    </div>
                                                                                    {el.checked ?
                                                                                        <img src='/images/tick-mark.svg' alt='' width={18} />
                                                                                        :
                                                                                        <img src='/images/grey-tick-mark.svg' alt='' />
                                                                                    }

                                                                                </div>
                                                                            )
                                                                        })}
                                                                        {
                                                                            testDetails?.testDetails?.questions?.length > 0 &&
                                                                            <div className='list-item'>
                                                                                <div>
                                                                                    <h4>{t('Additional Questions')}</h4>
                                                                                    <p>{testDetails?.testDetails?.questions?.length} {t('Questions')}</p>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className='candidate-input'>
                                                    {candidateDetails?.name ? null :
                                                        <div className='form-group'>
                                                            <input type='text' className='form-control' value={name} placeholder='Enter your name' style={{ color: '#fff' }} onChange={(e) => {
                                                                setName(e.target.value)
                                                                setNameError('')
                                                            }} />
                                                            <small style={{ color: 'red' }}>{nameError}</small>
                                                        </div>
                                                    }
                                                    <div className='form-group'>
                                                        <input type='text' className='form-control' value={code} placeholder='Enter Test Code (check invitation email)' style={{ color: '#fff' }} maxLength={6} onChange={(e) => {
                                                            setCode(e.target.value)
                                                            setCodeError('')
                                                        }} />
                                                        <small style={{ color: 'red' }}>{codeError}</small>
                                                    </div>
                                                    <div className='d-flex align-items-start mt-4'>
                                                        <input type='checkbox' style={{ width: 27, height: 27 }} onChange={(e) => setChecked(e.target.value)} />&nbsp;
                                                        <p> {t('By taking up this assessment, you are accepting to abide by our')} <bold style={{ textDecoration: 'underline' }}>{t('Terms & Agreement')}</bold></p>
                                                    </div>
                                                    <button className='form-control mt-2' disabled={(name && code && checked) ? false : true} style={{ background: (name && code && checked) ? '#009199' : 'grey' }} onClick={() => validate()}>{t('CONTINUE')}</button>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </Fragment>
                                :
                                active === 'diagnostic' ?
                                    <div className='diagnostic-holder'>
                                        <div className='diagnostic-text'>
                                            <img src='/images/dignostic.svg' alt='' />
                                            <h4>{t('Diagnostics')}</h4>
                                            <p>
                                                {t('Before you being your test, we would like to run a diagnostic test to ensure you are set up correctly. It will only take a couple of minutes')}
                                            </p>
                                        </div>
                                        <div className='dignostic-check'>
                                            <div className="accordion" id="accordionExample">
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="headingOne">
                                                        <button className="accordion-button d-flex justify-content-between" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                            <span>{t('System Check')}</span>
                                                            {(isBrowser && isNetwork && isOs && isScreen) ? <img src='/images/tick-mark.svg' alt='' width={18} /> : <img src='/images/error.svg' alt='' />}
                                                        </button>
                                                    </h2>
                                                    <div id="collapseOne" className={(isBrowser && isNetwork && isOs && isScreen) ? "accordion-collapse collapse" : "accordion-collapse collapse show"} aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            <div className='d-flex flex-wrap'>
                                                                <div className='check-item'>
                                                                    <img src='/images/browser-icon.svg' alt='' />
                                                                    <p>{t('Browser')}</p>
                                                                    {window.innerWidth < 767 &&
                                                                        (!isBrowser ?
                                                                            <img src='/images/error.svg' alt='' />
                                                                            : <img src='/images/tick-mark.svg' width={18} alt='' />)
                                                                    }
                                                                </div>
                                                                <div className='check-item'>
                                                                    <img src='/images/os-icon.svg' alt='' />
                                                                    <p>{t('Operating System')}</p>
                                                                    {window.innerWidth < 767 &&
                                                                        (!isOs ? <img src='/images/error.svg' alt='' /> :
                                                                            <img src='/images/tick-mark.svg' width={18} alt='' />)
                                                                    }
                                                                </div>
                                                                <div className='check-item'>
                                                                    <img src='/images/resolution.svg' alt='' />
                                                                    <p>{t('Screen Resoultion')}</p>
                                                                    {window.innerWidth < 767 &&
                                                                        (!isScreen ?
                                                                            <img src='/images/error.svg' alt='' /> :
                                                                            <img src='/images/tick-mark.svg' alt='' width={18} />)
                                                                    }
                                                                </div>
                                                                <div className='check-item'>
                                                                    <img src='/images/network.svg' alt='' />
                                                                    <p>{t('Network speed')}</p>
                                                                    {window.innerWidth < 767 &&
                                                                        (!isNetwork ? <img src='/images/error.svg' alt='' />
                                                                            : <img src='/images/tick-mark.svg' width={18} alt='' />)
                                                                    }
                                                                </div>
                                                            </div>
                                                            {window.innerWidth > 767 &&
                                                                <div className='d-flex'>
                                                                    <div className='check-item no-border'>
                                                                        {!isBrowser ?
                                                                            <img src='/images/error.svg' alt='' />
                                                                            : <img src='/images/tick-mark.svg' width={18} alt='' />
                                                                        }
                                                                    </div>
                                                                    <div className='check-item no-border'>
                                                                        {!isOs ? <img src='/images/error.svg' alt='' /> :
                                                                            <img src='/images/tick-mark.svg' width={18} alt='' />
                                                                        }
                                                                    </div>
                                                                    <div className='check-item no-border'>
                                                                        {!isScreen ?
                                                                            <img src='/images/error.svg' alt='' /> :
                                                                            <img src='/images/tick-mark.svg' alt='' width={18} />
                                                                        }
                                                                    </div>
                                                                    <div className='check-item no-border'>
                                                                        {!isNetwork ? <img src='/images/error.svg' alt='' />
                                                                            : <img src='/images/tick-mark.svg' width={18} alt='' />
                                                                        }
                                                                    </div>
                                                                </div>}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="headingTwo">
                                                        <button className="accordion-button collapsed d-flex justify-content-between" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                            <span>{t('Webcam')}</span>
                                                            {(imageSrc) ? <img src='/images/tick-mark.svg' alt='' width={18} /> : null}
                                                        </button>
                                                    </h2>
                                                    <div id="collapseTwo" className={(isBrowser && isNetwork && isOs && isScreen) ? "accordion-collapse collapse show" : "accordion-collapse collapse"} aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            {!imageSrc ?
                                                                <Fragment>
                                                                    <div>
                                                                        <Webcam
                                                                            audio={false}
                                                                            height={window.innerWidth > 767 ? 155 : 190}
                                                                            ref={webcamRef}
                                                                            screenshotFormat="image/jpeg"
                                                                            width={window.innerWidth > 767 ? 220 : 250}
                                                                            videoConstraints={videoConstraints}
                                                                            onUserMediaError={onUserMediaError}
                                                                        />
                                                                    </div>

                                                                </Fragment> :
                                                                <img src={imageSrc} alt='' width={window.innerWidth > 767 ? 220 : 250} height={155} />
                                                            }
                                                            {imageSrc ? null :
                                                                window.innerWidth > 767 ?
                                                                    <button className='next-btn' onClick={capture}>{t('Capture')}</button> :
                                                                    <div className='mt-2'>
                                                                        <button className='next-btn form-control' onClick={capture}>{t('Capture')}</button>
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            {imageSrc === '' ? null :
                                                window.innerWidth > 767 ?
                                                    <div className='mt-2'>
                                                        <button className='next-btn' disabled={imageSrc === ''} onClick={imageSrc ? goToIdentity : ''}>{t('NEXT:PHOTO VERIFICATION')}</button>
                                                    </div> :
                                                    <div className='mt-2 mx-4'>
                                                        <button className='next-btn form-control' disabled={imageSrc === ''} onClick={imageSrc ? goToIdentity : ''}>{t('NEXT:PHOTO VERIFICATION')}</button>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                    :
                                    active === 'identity' ?
                                        <div className='diagnostic-holder'>
                                            <div className='diagnostic-text'>
                                                <img src='/images/identity-icon.svg' alt='' />
                                                <h4>{t('Photo ID verification')}</h4>
                                                <p>
                                                    {t('Show your ID card in front of the camera and click take photo when the image is clear.')}
                                                </p>
                                            </div>
                                            <div className='dignostic-check text-center'>
                                                <p>{t('Photo Id')}</p>
                                                {!identity ?
                                                    <Fragment>
                                                        <div>
                                                            <Webcam
                                                                audio={false}
                                                                height={280}
                                                                ref={webcamRef1}
                                                                screenshotFormat="image/jpeg"
                                                                width={window.innerWidth > 767 ? 416 : 332}
                                                                videoConstraints={videoConstraints1}
                                                            />
                                                        </div>
                                                        {/* <button className='capture-btn'><img src='/images/capture.png' alt='' width={20} /></button> */}
                                                    </Fragment> :
                                                    <img src={identity} alt='' width={window.innerWidth > 767 ? 416 : 332} height={280} />
                                                }
                                                <div className={window.innerWidth > 767 ? 'd-flex flex-column align-items-center mt-2' : "text-center mt-2 mx-3"}>
                                                    <button className='next-btn d-flex justify-content-center align-items-center' onClick={captureIdentity}>{'TAKE PHOTO'}</button><br />
                                                    {/* <button className='next-btn d-flex justify-content-center align-items-center' style={{ background: identity ? '#009199' : 'grey' }} disabled={!identity} onClick={() => getTestDetail()} >
                                                        <span>{t('START TEST IN')}</span>&nbsp;
                                                        <div className='d-flex align-items-center'>
                                                            <div>{minutes.toString().padStart(2, '0')}</div>:<div>{seconds.toString().padStart(2, '0')}</div>
                                                        </div>
                                                    </button> */}
                                                </div>
                                            </div>
                                        </div> :
                                        active === 'test-summary' ?
                                            (loadingFeedback ?
                                                <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}>
                                                    <img src='/images/spinner.svg' alt='' />
                                                </div> :
                                                <div className='test-detail-progress'>
                                                    <h1>{t('Test Progress and Summary')}</h1>
                                                    <p>{t('This page will be show you the test progress. You will come here after completing each test')} </p>
                                                    {
                                                        window.innerWidth > 767 ?
                                                            <div className='d-flex align-items-center justify-content-center flex-wrap'>

                                                                <div className='test-list' style={{ width: '32%' }}>
                                                                    <div className="progress-circular">
                                                                        <CircularProgressbarWithChildren
                                                                            value={testSummary?.completedPercentage}
                                                                            maxValue={100}
                                                                            minValue="0"
                                                                            styles={{
                                                                                root: {
                                                                                    width: "100%",
                                                                                    fontSize: 10,
                                                                                    borderRadius: 150,
                                                                                    marginTop: 0,
                                                                                    background: "#fff",
                                                                                    verticalAlign: "middle",
                                                                                    position: "relative",
                                                                                },
                                                                                path: {
                                                                                    strokeLinecap: "square",
                                                                                    stroke: "#02C573",
                                                                                    strokeWidth: 8,
                                                                                },
                                                                                trail: {
                                                                                    // Trail color
                                                                                    stroke: "#F2f2f3",
                                                                                    strokeWidth: 8,
                                                                                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                                                    strokeLinecap: "round",
                                                                                },
                                                                            }}
                                                                        >
                                                                            <div
                                                                                className="text-center"
                                                                                style={{
                                                                                    fontSize: 21,
                                                                                    color: "#3d4143",
                                                                                    fontFamily: "Poppins",
                                                                                }}
                                                                            >
                                                                                <p className='text-center' style={{ fontWeight: 600, fontSize: 48, color: '#3d4143', marginBottom: 40 }}>
                                                                                    {testSummary?.completedPercentage + '%'}
                                                                                </p>
                                                                                <hr />
                                                                                <strong className='text-center'>
                                                                                    {testSummary?.totalCompletedTest}/{testSummary?.totalTest}
                                                                                </strong>
                                                                                <br />
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 14,
                                                                                        color: "#555C5E",
                                                                                        fontFamily: "Poppins",
                                                                                    }}
                                                                                >
                                                                                    {t('Test Completed')}
                                                                                </span>
                                                                            </div>
                                                                        </CircularProgressbarWithChildren>
                                                                    </div>
                                                                </div>
                                                                <div style={{ borderLeft: '1px solid #D7DADB', margin: '0px 75px', minHeight: 450 }}></div>
                                                                <div className='test-list'>
                                                                    {testSummary?.testDetails?.tests.sort((a, b) => a.orderId - b.orderId).map(el => {
                                                                        return (
                                                                            <div className='list-item'>
                                                                                <div>
                                                                                    <h4>{el.testName}</h4>
                                                                                    <p>{el.questionCount} {t('Questions')} {el.duration} {t('Mins')} | {el.languageCode}</p>
                                                                                </div>
                                                                                {el.candidateTestStatus === 3 ?
                                                                                    <img src='/images/tick-mark.svg' alt='' width={18} />
                                                                                    :
                                                                                    <img src='/images/grey-tick-mark.svg' alt='' />
                                                                                }

                                                                            </div>
                                                                        )
                                                                    })}
                                                                    {
                                                                        testSummary?.testDetails?.questions?.length > 0 &&
                                                                        <div className='list-item'>
                                                                            <div>
                                                                                <h4>{t('Additional Questions')}</h4>
                                                                                <p>{testSummary?.testDetails?.questions?.length} {t('Questions')}</p>
                                                                            </div>
                                                                        </div>
                                                                    }

                                                                    <div className='next-btns'>
                                                                        {/* <button onClick={() => changeActive('test')}>Next test starts in: 9</button> */}
                                                                        <button className='d-flex align-items-center justify-content-center' onClick={startTest}>
                                                                            <span>{t('Test start in')}</span> &nbsp;
                                                                            <div className='d-flex align-items-center'>
                                                                                <div>{minutes.toString().padStart(2, '0')}</div>:<div>{seconds.toString().padStart(2, '0')}</div>
                                                                            </div>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div> :
                                                            <div className=''>
                                                                <div className='mt-3' style={{ marginLeft: 35 }}>
                                                                    <ProgressBar
                                                                        labelColor={'#3D4143'} bgcolor={'#02C573'} fill={'#212425'} completed={testSummary?.completedPercentage}
                                                                    />
                                                                    <div style={{ fontSize: 14, marginTop: -25, color: "#555C5E", fontFamily: "Poppins", }}>
                                                                        {testSummary?.totalCompletedTest}/{testSummary?.totalTest}&nbsp;
                                                                        {t('Test Completed')}
                                                                    </div>
                                                                </div>
                                                                <div className='test-list'>
                                                                    {testSummary?.testDetails?.tests.sort((a, b) => a.orderId - b.orderId).map(el => {
                                                                        return (
                                                                            <div className='list-item'>
                                                                                <div>
                                                                                    <h4>{el.testName}</h4>
                                                                                    <p>{el.questionCount} {t('Questions')} {el.duration} {t('Mins')} | {el.languageCode}</p>
                                                                                </div>
                                                                                {el.candidateTestStatus === 3 ?
                                                                                    <img src='/images/tick-mark.svg' alt='' width={18} />
                                                                                    :
                                                                                    <img src='/images/grey-tick-mark.svg' alt='' />
                                                                                }

                                                                            </div>
                                                                        )
                                                                    })}
                                                                    {
                                                                        testSummary?.testDetails?.questions?.length > 0 &&
                                                                        <div className='list-item'>
                                                                            <div>
                                                                                <h4>{t('Additional Questions')}</h4>
                                                                                <p>{testSummary?.testDetails?.questions?.length} {t('Questions')}</p>
                                                                            </div>
                                                                        </div>
                                                                    }

                                                                    <div className='next-btns'>
                                                                        {/* <button onClick={() => changeActive('test')}>Next test starts in: 9</button> */}
                                                                        <button onClick={startTest}>{t('Test Start in')} <div>{minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}</div> </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    }
                                                </div>
                                            )
                                            :
                                            active === 'test' ?
                                                (loadingFeedback ?
                                                    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}>
                                                        <img src='/images/spinner.svg' alt='' />
                                                    </div>
                                                    : (question !== null &&

                                                        (question?.questionTypeID === 7 ?
                                                            <Fragment>
                                                                <div className='timer-div'>
                                                                    <div id="revese-timer" >
                                                                        <div className="base-timer" >
                                                                            <svg className="base-timer__svg" viewBox="0 0 100 80" xmlns="http://www.w3.org/2000/svg">
                                                                                <g className="base-timer__circle">
                                                                                    <circle className="base-timer__path-elapsed" cx="50" cy="40" r="35"></circle>
                                                                                    <path
                                                                                        id="base-timer-path-remaining"
                                                                                        stroke-dasharray="283"
                                                                                        className={`base-timer__path-remaining ${remainingPathColor}`}
                                                                                        d="M 50, 40
                                                                                       m -35, 0
                                                                                       a 35,35 0 1,0 70,0
                                                                                       a 35,35 0 1,0 -70,0
                                                                                       "
                                                                                    ></path>
                                                                                </g>
                                                                            </svg>
                                                                            <span id="base-timer-label" className="notranslate base-timer__label">{formatTime(
                                                                                timeLeft
                                                                            )}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <Main
                                                                    question={question}
                                                                    isNextTest={isNextTest}
                                                                    isTestCompleted={isTestCompleted}
                                                                    getNext={getNextCodingQues}
                                                                    getSummary={getSummary}
                                                                    token={token}
                                                                    inviteToken={candidateDetails?.invitationToken}
                                                                    setActive={() => setActive('feedback')}
                                                                />
                                                                {
                                                                    <div style={{ position: 'absolute', bottom: 0, right: 0, visibility: 'hidden' }}>
                                                                        {<Webcam
                                                                            style={{ width: "100%", height: 250, display: 'block' }}
                                                                            audio={false}
                                                                            ref={webcamRef3}
                                                                            // screenshotFormat="image/jpeg"
                                                                            mirrored={true}
                                                                            imageSmoothing={true}
                                                                            screenshotQuality={1}
                                                                        />}
                                                                    </div>
                                                                }
                                                            </Fragment>
                                                            : question?.questionTypeID === 8 ?
                                                                <div className='test-view'>
                                                                    <div className='timer-div'>
                                                                        <div id="revese-timer" >
                                                                            <div className="base-timer" >
                                                                                <svg className="base-timer__svg" viewBox="0 0 100 80" xmlns="http://www.w3.org/2000/svg">
                                                                                    <g className="base-timer__circle">
                                                                                        <circle className="base-timer__path-elapsed" cx="50" cy="40" r="35"></circle>
                                                                                        <path
                                                                                            id="base-timer-path-remaining"
                                                                                            stroke-dasharray="283"
                                                                                            className={`base-timer__path-remaining ${remainingPathColor}`}
                                                                                            d="M 50, 40
                                                                                       m -35, 0
                                                                                       a 35,35 0 1,0 70,0
                                                                                       a 35,35 0 1,0 -70,0
                                                                                       "
                                                                                        ></path>
                                                                                    </g>
                                                                                </svg>
                                                                                <span id="base-timer-label" className="notranslate base-timer__label">{formatTime(
                                                                                    timeLeft
                                                                                )}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <Fragment>
                                                                        <h6>{question?.testName} / {question?.referenceName}</h6>
                                                                        <hr style={{ margin: '0.5rem  0rem 1rem 0rem' }} />
                                                                        {
                                                                            <div className='row' style={{ minHeight: window.innerWidth > 767 ? 280 : '' }}>
                                                                                <div className='col-md-8'>
                                                                                    <div className='d-flex align-items-center'>
                                                                                        <img src='/images/ques-icon.svg' alt='' width={32} />&nbsp;&nbsp;
                                                                                        <div>
                                                                                            <h5>{question?.currentQuestion}/{question?.totalQuestion}</h5>
                                                                                            <p>{question?.questionTypeID === 1 ? 'Multiple Choice' : question?.questionTypeID === 8 ? 'Big Five' : 'Essay Type'}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='mt-3'>
                                                                                        <h3>{question?.question}</h3>
                                                                                    </div>
                                                                                </div>
                                                                                <div className={window.innerWidth > 767 ? 'col-md-4' : 'col-md-4 mt-3'}>
                                                                                    <div className='d-flex flex-column  align-items-start'>
                                                                                        {
                                                                                            emojis && emojis?.map((el, i) => {
                                                                                                return (
                                                                                                    <div className={activeEmoji === el.id ? 'question-option active' : 'question-option'} onClick={() => {
                                                                                                        setActiveEmoji(el.id)
                                                                                                        setQid(question.candidateQuestionId)
                                                                                                    }}>
                                                                                                        <img src={el.emoji} alt='' width={80} />&nbsp;&nbsp;
                                                                                                        {/* <div className='circle' style={{ background: el.color, opacity: active === el.id ? 1 : 0.2 }}></div> */}
                                                                                                        <span style={{ fontWeight: activeEmoji === el.id ? 700 : 400 }}>{el.text}</span>
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                        {/* {
                                                                                            question?.questionOptions?.map((el, i) => {
                                                                                                return (
                                                                                                    <div key={el.questionOptionID} className={option === el.questionOptionID ? 'ques-option select-option cursor-pointer' : 'ques-option cursor-pointer'} onClick={() => selectOption(el.questionOptionID, question.candidateQuestionId)}>
                                                                                                        <p><strong>{i === 0 ? 'A' : i === 1 ? 'B' : i === 2 ? 'C' : i === 3 ? 'D' : i === 4 ? 'E' : i === 5 ? 'F' : i === 6 ? 'G' : 'H'}</strong>&nbsp;&nbsp;&nbsp;&nbsp;{el.questionOption}</p>
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                        } */}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        <hr style={{ margin: '2rem 0rem' }} />
                                                                        <div className='text-right'>
                                                                            <button className='next-btn' disabled={(!option && !activeEmoji) ? true : nextLoading ? true : false} style={{ background: (!option && !activeEmoji) ? 'grey' : '#009199' }} onClick={() => (!isNextTest && !isTestCompleted) ? getNext() : (isTestCompleted) ? setActive('feedback') : isNextTest ? getSummary() : null}>{t('Next')}</button>
                                                                        </div>
                                                                    </Fragment>

                                                                    {
                                                                        <div style={{ position: 'absolute', bottom: 0, right: 0, visibility: 'hidden' }}>
                                                                            {<Webcam
                                                                                style={{ width: "100%", height: 250, display: 'block' }}
                                                                                audio={false}
                                                                                ref={webcamRef3}
                                                                                // screenshotFormat="image/jpeg"
                                                                                mirrored={true}
                                                                                imageSmoothing={true}
                                                                                screenshotQuality={1}
                                                                            />}
                                                                        </div>
                                                                    }
                                                                </div> :
                                                                question?.questionTypeID === 5 ?
                                                                    <div className='test-view'>

                                                                        <div className='timer-div'>
                                                                            <div id="revese-timer" >
                                                                                <div className="base-timer" >
                                                                                    <svg className="base-timer__svg" viewBox="0 0 100 80" xmlns="http://www.w3.org/2000/svg">
                                                                                        <g className="base-timer__circle">
                                                                                            <circle className="base-timer__path-elapsed" cx="50" cy="40" r="35"></circle>
                                                                                            <path
                                                                                                id="base-timer-path-remaining"
                                                                                                stroke-dasharray="283"
                                                                                                className={`base-timer__path-remaining ${remainingPathColor}`}
                                                                                                d="
                        M 50, 40
                        m -35, 0
                        a 35,35 0 1,0 70,0
                        a 35,35 0 1,0 -70,0
                    "
                                                                                            ></path>
                                                                                        </g>
                                                                                    </svg>
                                                                                    <span id="base-timer-label" className="notranslate base-timer__label">{formatTime(
                                                                                        timeLeft
                                                                                    )}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            <Fragment>
                                                                                <h6>{question?.testName} / {question?.referenceName}</h6>
                                                                                <hr style={{ margin: '0.5rem  0rem 1rem 0rem' }} />
                                                                                {
                                                                                    <div className='row' style={{ minHeight: window.innerWidth > 767 ? 280 : '' }}>
                                                                                        <div className='col-md-6'>
                                                                                            <div className='d-flex align-items-center'>
                                                                                                <img src='/images/ques-icon.svg' alt='' width={32} />&nbsp;&nbsp;
                                                                                                <div>
                                                                                                    <h5>{question?.currentQuestion}/{question?.totalQuestion}</h5>
                                                                                                    <p>{question?.questionTypeID === 1 ? 'Multiple Choice' : question?.questionTypeID === 9 ? 'MBTI Type' : 'Essay Type'}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className='mt-3'>
                                                                                                <h3 dangerouslySetInnerHTML={{ __html: question.question }} />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className={window.innerWidth > 767 ? 'col-md-6 mt-3' : 'col-md-6 mt-3'}>
                                                                                            <textarea rows={4} cols={4} className='form-control' value={answerText} onChange={(e) => setAnswerText(e.target.value)}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                                <hr style={{ margin: '2rem 0rem' }} />
                                                                                <div className='text-right'>
                                                                                    <button className='next-btn' disabled={(!option && !answerText) ? true : nextLoading ? true : false} style={{ background: (!option && !answerText) ? 'grey' : '#009199' }} onClick={() => (!isNextTest && !isTestCompleted) ? getNext() : (isTestCompleted) ? setActive('feedback') : isNextTest ? getSummary() : null}>{t('Next')}</button>
                                                                                </div>
                                                                            </Fragment>}
                                                                        {
                                                                            <div style={{ position: 'absolute', bottom: 0, right: 0, visibility: 'hidden' }}>
                                                                                {<Webcam
                                                                                    style={{ width: "100%", height: 250, display: 'block' }}
                                                                                    audio={false}
                                                                                    ref={webcamRef3}
                                                                                    // screenshotFormat="image/jpeg"
                                                                                    mirrored={true}
                                                                                    imageSmoothing={true}
                                                                                    screenshotQuality={1}
                                                                                />}
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    :
                                                                    <div className='test-view'>

                                                                        <div className='timer-div'>
                                                                            <div id="revese-timer" >
                                                                                <div className="base-timer" >
                                                                                    <svg className="base-timer__svg" viewBox="0 0 100 80" xmlns="http://www.w3.org/2000/svg">
                                                                                        <g className="base-timer__circle">
                                                                                            <circle className="base-timer__path-elapsed" cx="50" cy="40" r="35"></circle>
                                                                                            <path
                                                                                                id="base-timer-path-remaining"
                                                                                                stroke-dasharray="283"
                                                                                                className={`base-timer__path-remaining ${remainingPathColor}`}
                                                                                                d="
                        M 50, 40
                        m -35, 0
                        a 35,35 0 1,0 70,0
                        a 35,35 0 1,0 -70,0
                    "
                                                                                            ></path>
                                                                                        </g>
                                                                                    </svg>
                                                                                    <span id="base-timer-label" className="notranslate base-timer__label">{formatTime(
                                                                                        timeLeft
                                                                                    )}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            <Fragment>
                                                                                <h6>{question?.testName} / {question?.referenceName}</h6>
                                                                                <hr style={{ margin: '0.5rem  0rem 1rem 0rem' }} />
                                                                                {
                                                                                    <div className='row' style={{ minHeight: window.innerWidth > 767 ? 280 : '' }}>
                                                                                        <div className='col-md-6'>
                                                                                            <div className='d-flex align-items-center'>
                                                                                                <img src='/images/ques-icon.svg' alt='' width={32} />&nbsp;&nbsp;
                                                                                                <div>
                                                                                                    <div className='d-flex align-items-center'>
                                                                                                        <h5>{question?.currentQuestion}</h5> /
                                                                                                        <h5> {question?.totalQuestion}</h5>
                                                                                                    </div>
                                                                                                    <p>{question?.questionTypeID === 1 ? 'Multiple Choice' : question?.questionTypeID === 9 ? 'MBTI Type' : 'Essay Type'}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className='mt-3'>
                                                                                                <h3 dangerouslySetInnerHTML={{ __html: question?.question }} />
                                                                                            </div>
                                                                                        </div>
                                                                                        {
                                                                                            question?.questionTypeID === 5 ?
                                                                                                <div className={window.innerWidth > 767 ? 'col-md-6 mt-3' : 'col-md-6 mt-3'}>
                                                                                                    <textarea rows={4} cols={4} className='form-control' value={answerText} onChange={(e) => setAnswerText(e.target.value)}
                                                                                                    />
                                                                                                </div>
                                                                                                : <div className={window.innerWidth > 767 ? 'col-md-6' : 'col-md-6 mt-3'}>
                                                                                                    <div className='d-flex flex-column align-items-end'>
                                                                                                        {
                                                                                                            question?.questionOptions?.map((el, i) => {
                                                                                                                return (
                                                                                                                    <div key={el.questionOptionID} className={option === el.questionOptionID ? 'ques-option select-option cursor-pointer' : 'ques-option cursor-pointer'} onClick={() => selectOption(el.questionOptionID, question.candidateQuestionId)}>
                                                                                                                        <div className='d-flex align-items-center'><strong ><span className='notranslate'>{i === 0 ? 'A' : i === 1 ? 'B' : i === 2 ? 'C' : i === 3 ? 'D' : i === 4 ? 'E' : i === 5 ? 'F' : i === 6 ? 'G' : 'H'}</span></strong>&nbsp;&nbsp;&nbsp;&nbsp;<p>{el.questionOption}</p></div>
                                                                                                                    </div>
                                                                                                                )
                                                                                                            })
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                        }
                                                                                    </div>
                                                                                }
                                                                                <hr style={{ margin: '2rem 0rem' }} />
                                                                                <div className='text-right'>
                                                                                    <button className='next-btn' disabled={(!option && !answerText) ? true : nextLoading ? true : false} style={{ background: (!option && !answerText) ? 'grey' : '#009199' }} onClick={() => (!isNextTest && !isTestCompleted) ? getNext() : (isTestCompleted) ? setActive('feedback') : isNextTest ? getSummary() : null}>{t('Next')}</button>
                                                                                </div>
                                                                            </Fragment>}
                                                                        {
                                                                            <div style={{ position: 'absolute', bottom: 0, right: 0, visibility: 'hidden' }}>
                                                                                {<Webcam
                                                                                    style={{ width: "100%", height: 250, display: 'block' }}
                                                                                    audio={false}
                                                                                    ref={webcamRef3}
                                                                                    // screenshotFormat="image/jpeg"
                                                                                    mirrored={true}
                                                                                    imageSmoothing={true}
                                                                                    screenshotQuality={1}
                                                                                />}
                                                                            </div>
                                                                        }
                                                                    </div>
                                                        )
                                                    )
                                                )
                                                :
                                                active === 'feedback' ?
                                                    (loadingFeedback ?
                                                        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}>
                                                            <img src='/images/spinner.svg' alt='' />
                                                        </div>
                                                        : <div className='test-detail-progress'>
                                                            <div className='text-center' >
                                                                <img src='/images/tick-mark.svg' alt='' />
                                                            </div>
                                                            <h1 className='mt-3'>{t('Assessment Completed')}</h1>
                                                            <div className={window.innerWidth > 767 ? 'd-flex  justify-content-center flex-wrap mt-4' : 'd-flex flex-wrap mt-4'}>
                                                                <div className='test-list' style={{ width: window.innerWidth > 767 ? '48%' : '100%' }}>
                                                                    {testSummary?.testDetails?.tests.sort((a, b) => a.orderId - b.orderId).map(el => {
                                                                        return (
                                                                            <div className='list-item'>
                                                                                <div>
                                                                                    <h4>{el.testName}</h4>
                                                                                    <p>{el.questionCount} {t('Questions')} {el.duration} {t('Mins')}</p>
                                                                                </div>
                                                                                {el.candidateTestStatus === 3 ?
                                                                                    <img src='/images/tick-mark.svg' alt='' width={18} />
                                                                                    :
                                                                                    <img src='/images/grey-tick-mark.svg' alt='' />
                                                                                }

                                                                            </div>
                                                                        )
                                                                    })}
                                                                    {
                                                                        testSummary?.testDetails?.questions?.length > 0 &&
                                                                        <div className='list-item'>
                                                                            <div>
                                                                                <h4>{t('Additional Questions')}</h4>
                                                                                <p>{testSummary?.testDetails?.questions?.length} {t('Questions')}</p>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                {window.innerWidth > 767 && <div style={{ borderLeft: '1px solid #D7DADB', margin: '0px 25px', minHeight: 450 }}></div>}
                                                                <div className='test-list' style={{ width: window.innerWidth > 767 ? '30%' : '100%' }}>
                                                                    <p>{t('Thank you for completing the assessment. We will get in touch with you about next steps shortly. Please take a moment to share your assessment experience with us')}</p>
                                                                    <div style={{ padding: window.innerWidth > 767 ? '' : '5px 30px' }}>
                                                                        <label>{t('Overall rating')} </label>
                                                                        <div className='d-flex mt-2'>
                                                                            {[1, 2, 3, 4, 5].map((star) => (
                                                                                <Fragment>
                                                                                    <StarIcon
                                                                                        key={star}
                                                                                        filled={star <= rating}
                                                                                        onClick={() => handleStarClick(star)}
                                                                                    />&nbsp;&nbsp;
                                                                                </Fragment>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                    <div className='form-group mt-3' style={{ padding: window.innerWidth > 767 ? '' : '5px 30px' }}>
                                                                        <label>{t('Comments if any')} </label>
                                                                        <textarea rows={3} className='form-control mt-2' onChange={(e) => setComment(e.target.value)} />
                                                                    </div>
                                                                    {
                                                                        window.innerWidth > 767 ?
                                                                            <button className='next-btn mt-3' disabled={rating === 0} style={{ background: rating === 0 ? 'grey' : '#009199' }} onClick={() => submitFeedback()}>{t('Submit')}</button>
                                                                            : <div className='text-center mb-3'>
                                                                                <button className='next-btn mt-3' disabled={rating === 0} style={{ background: rating === 0 ? 'grey' : '#009199' }} onClick={() => submitFeedback()}>{t('Submit')}</button>
                                                                            </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) :
                                                    active === 'test-complete' ?
                                                        <div className='test-detail-progress text-center' style={{ padding: '125px 30px' }}>
                                                            <div className='text-center' >
                                                                <img src='/images/tick-mark.svg' alt='' />
                                                            </div>
                                                            {window.innerWidth > 767 ?
                                                                <h1 className='mt-3'>{t('Assessment Completed')}</h1> :
                                                                <h1 className='mt-3 text-center'>{t('Thank you')}</h1>
                                                            }
                                                            <div className='d-flex  justify-content-center flex-wrap mt-2'>

                                                                <div className='test-list ' style={{ width: window.innerWidth > 767 ? '70%' : '100%' }}>
                                                                    <img src='/images/tick.svg' alt='' />
                                                                    <p className='text-center'>{t('You can now close the browser window and exit')}</p>

                                                                    <button className='next-btn mt-3' onClick={closeWindow}>{t('Close Window')}</button>
                                                                </div>
                                                            </div>
                                                        </div> :
                                                        null)
                        }

                        {/* Create custom test modal */}
                        <Modal
                            show={(active === 'summary') && isCookie}
                            size="sm"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            dialogClassName={"cookie-modal"}
                        >
                            <Modal.Body>
                                <div className='mb-2'>
                                    <img src='/images/cookie-icon.svg' alt='' />
                                </div>
                                <p>{t('We require that you enable cookies for us to provide an optimum assessment experience')}</p>
                                <div className='mt-2'>
                                    <button onClick={() => {
                                        localStorage.setItem('token', token);
                                        setIsCookie(false)
                                    }}>{t('ACCEPT ALL COOKIES')}</button>
                                    <button onClick={() => setIsCookie(false)}>{t('ACCEPT REQUIRED COOKIES')}</button>
                                    <button onClick={() => setIsCookie(false)}>{t('DECLINE COOKIES')}</button>

                                </div>
                            </Modal.Body>

                        </Modal>
                        <Modal
                            show={isAlert}
                            size="md"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            dialogClassName={"warning-modal"}
                        >
                            <Modal.Body  >
                                <div className='text-center p-2'>
                                    <img src='/images/alert.png' alt='' width={50} />
                                    <h3 className='mt-2'>{t('Remainder: 2 Minutes left!')}</h3>
                                    <p className='mt-2'>{t('You have 2 minutes left to complete the test. Please ensure all questions are answered')}.</p>
                                </div>

                            </Modal.Body>

                        </Modal>
                    </div> :
                    <div style={{ background: '#fff', width: '100%', height: '60vh', }}>
                        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}>
                            <h1>{message}</h1>
                        </div>
                    </div>
                )
            }

            <Modal
                show={isConfirm}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                // onHide={this.handleCloseModal}
                dialogClassName={"confirm-modal "}
            >
                <div className="p-4" >
                    <h1>
                        {t('Have you taken your photo correctly? Would you like to proceed or retake it')} ?
                    </h1>
                    <div className="save-btn">
                        <button onClick={goAhead}>{t('Proceed')}</button>&nbsp;&nbsp;
                        <button onClick={() => {
                            setIsConfirm(false)
                            reCapture();
                        }}>{t('Retake Photo')}</button>
                    </div>
                </div>
            </Modal>

            <Modal
                show={isAllow}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                // onHide={this.handleCloseModal}
                dialogClassName={"confirm-modal"}
            >
                <div className="p-3 text-center">
                    <Fragment>
                        <h1 style={{ fontSize: 18 }}>You have permission denied for the webcam. Please allow the webcam from the browser settings and reload the page.
                            {error ? error : ''}
                        </h1>
                        <div className="save-btn">
                            <button style={{ width: 112 }} onClick={() => {
                                window.location.reload(false);
                                setIsAllow(false)
                            }}>Reload Now</button>
                        </div>
                    </Fragment>
                </div>
            </Modal>
        </Fragment >
    )
}
